<template>

  <div class="row" v-if="produit">
    <div class="col-lg-8">
       <div class="ibox">
        <div class="ibox-content">
          <h1>Produit</h1>
          <div class="form-group">
            <label for="">Titre :</label>
            <input v-model="v$.produit.titre.$model" class="form-control" :class="{ 'form-error': v$.produit.titre.$errors.length }"/>
          </div>
          <div class="form-group">
            <label for="">Description :</label>            
            <ckeditor :editor="editor" :config="editorConfig" v-model="produit.description"></ckeditor>
          </div>
          <div class="form-group">
            <label for="">Stock:</label>
            <input v-model="v$.produit.stock.$model" class="form-control" :class="{ 'form-error': v$.produit.stock.$errors.length }" />
          </div>
          <div class="form-group">
            <label for="">Prix HT:</label>
            <input v-model="v$.produit.prix.$model" class="form-control" :class="{ 'form-error': v$.produit.prix.$errors.length }"/>
          </div>
          <div class="form-group">
            <label for="">Contenance :</label>
            <select name="" id="" v-model="v$.produit.contenance.$model"  class="form-control" :class="{ 'form-error': v$.produit.contenance.$errors.length }">
              <option value="50cl">50cl</option>
              <option value="75cl">75cl</option>
							<option value="1,5l">1,5l</option>
							<option value="5l">5l</option>
            </select>            
          </div>
          <div>
            <button v-on:click="saveData()" class="btn btn-primary">Valider</button>
          </div>
        </div>
       </div>
    </div>
    <div class="col-md-4">
      <div class="ibox">
        <div class="ibox-content">
          <h2>Image</h2>
          <div v-if="produit.image">
            <img :src="produit.image.full_path" width="200" alt="">
            <button class="btn btn-default" @click="removeDocument()">X</button>          
          </div>
          <FileUploader :uploadurl="uploadImageUrl" @add-document="addDocument" />    
        </div>
      </div>

      <!-- VIDEO -->
      <div class="ibox">
          <div class="ibox-content">
              <h2>Vidéos de l'experience</h2>              
              <VideoExperienceUploader 
                :uploadurl="uploadVideoUrl" 
                :videos="produit.videos_experience"
                @add-video="addVideo"
                @delete-video="removeVideo" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { h } from 'vue'

import FileUploader from "@/components/FileUploader";
import { required, minLength, numeric } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import VideoExperienceUploader from '@/components/VideoExperienceUploader'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
  name: "Produit",
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      produit: {},
      editor: ClassicEditor,
      editorConfig : {
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
      }
    };
  },
  validations() {
      return {
        produit: {
          titre: { 
              required,
              minLength: minLength(4)
          },
          prix: { 
              required,
              numeric
          },
          stock: { 
              required,
              numeric
          },
          contenance: { 
              required
          }
        },
        
      }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {
    fetchData() {
      
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });  
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/produit/" +
            this.$route.params.uuid,
          {}
        )
        .then((resp) => {          
          loading.close();
          this.produit = resp.data;          
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    saveData() {
      this.v$.$touch()
      if( !this.v$.$invalid){
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          }); 
        axios
          .post(
            process.env.VUE_APP_API + "/producteur/produit/" +
              this.produit.uuid,
            this.produit
          )
          .then((resp) => {          
            loading.close();
            this.produit = resp.data;
            this.$notify({
              title: 'Produit enregistré',
              message: h('i', { style: 'color: teal' }, '')
            });
          })
          .catch((error) => {
            console.log("ERROR", error);
          });
      }
    },
    addDocument(doc){
      this.produit.image = doc
      this.saveData()
    },
    removeDocument(){
      this.produit.image = null;      
      this.saveData()
    },
    addVideo(doc){
      let videoExperience = {
        video: doc
      }
      if(!this.produit.videos_experience) this.produit.videos_experience = [];
      this.produit.videos_experience.push(videoExperience);
      this.saveData()
    },
    removeVideo(video){      
      this.produit.videos_experience = this.produit.videos_experience.filter( v => {        
        return v.id != video.id
      })
      //this.saveData()
    },
  },
  computed: {    
    uploadImageUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/image'
    },
    uploadVideoUrl() {
      return process.env.VUE_APP_API + '/producteur/document/upload/video'
    },
  },
  components: {
    FileUploader,
    VideoExperienceUploader,
    ckeditor: CKEditor.component
  },
};
</script>